export const consumableList = [
    {
        id: 'c01',
        name: 'Potion of Healing',
        price: '50g',
        type: 'Consumable',
        img: 'default',
        summary: 'A small potion healing 2d4+2',
        description: 'A small red viscous liquid in a glass flask with weak healing properties.',
        effect: "Heals 2d4+4 health as an action. 1 time use",
    },
    {
        id: 'c02',
        name: 'Cold Brew in a Can',
        price: '50g',
        type: 'Consumable',
        img: 'default',
        summary: 'Take a chug from this bitter to make you alert',
        description: 'This tall stylish can assures you the delicious concoction within will shrug away that 2p.m. feeling.',
        effect: 'Provides advantage on initiative rolls for the next hour. One time use.',

    },
    {
        id: 'c03',
        name: 'Double Shot Arrows',
        price: '25g',
        type: 'Consumable',
        img: 'default',
        summary: 'Kill 2 birds with one stone... arrow',
        description: 'It feels like one solid arrow, but it looks like two... Or maybe that\'s the ale',
        effect: 'Firing this arrow will inflict your bow\'s damage twice. One time use.',

    }
    ,{
        id: 'c04',
        name: 'Flask of Perpetual Liquor',
        price: '50g',
        type: 'Consumable',
        img: 'default',
        summary: 'Drink responsibly friends',
        description: 'A small, ordinary flask that never runs out!',
        effect: 'Drink \'till you\'re drunk, then keep drinking.',
    },{
        id: 'c05',
        name: 'Super Spicy Salsa',
        price: '20g',
        type: 'Consumable',
        img: 'default',
        summary: 'OOOOOh baby it\'s spicy!',
        description: 'Once you get a taste of this super spicy salsa, you\'ll be feeling warm and toasty.',
        effect: 'Those who consume this salsa will have resistance to extreme cold for 8 hours. Can be consumed by multiple people (it\'s polite to share). Contains 1 portion of salsa. Chips not included.',
    },{
        id: 'c05',
        name: 'Super Spicy Salsa',
        price: '20g',
        type: 'Consumable',
        img: 'default',
        summary: 'OOOOOh baby it\'s spicy!',
        description: 'Once you get a taste of this super spicy salsa, you\'ll be feeling warm and toasty.',
        effect: 'Those who consume this salsa will have resistance to extreme cold for 8 hours. Can be consumed by multiple people (it\'s polite to share). Contains 1 portion of salsa. Chips not included.',
    },{
        id: 'c05',
        name: 'A Bag of Magic Tricks',
        price: '100g',
        type: 'Consumable',
        img: 'default',
        summary: 'Please please please do not open the bag in the store!',
        description: 'This rather plain leather sack is tightly bound with wire. It\'s oldy heavy for a seemingly empty bag. You\'re assured there is a spell in here though',
        effect: 'When opened, (as a bonus action) the user most roll a d20. One of many magical spells or effects will be launched out of the bag. One time use. Limit 1 per customer.',
    }
];

export const equipmentList = [
    {
        id: 'e01',
        name: 'Magnified Glass',
        price: '200g',
        type: 'Trinket',
        img: './trinket.png',
        summary: 'An essential item for aspiring sluethers',
        description: 'A small, finely crafted spy glass that allows the user to see with excrutiating detail',
        effect: 'Provides advantage on investigation checks once per day.',
    },
    {
        id: 'e02',
        name: 'Mits of Schocking Grasp',
        price: '100g',
        type: 'Armor - Gloves',
        img: './armor.png',
        summary: 'At least twice as effective as rubbing your feet on carpet',
        description: 'These mits crackle with energy, allowing the user to command lightenting from their fingertips!',
        effect: 'Casts the spell "shocking grasp". Has 1 charge per day.',
    },
    {
        id: 'e03',
        name: 'Freezing Touch Boomerang',
        price: '200g',
        type: 'Ranged Weapon',
        img: './weap.png',
        summary: 'And you said boomerangs aren\'t cool',
        description: 'This shiny, glassy boomerang will punish foes with the power of ice.',
        effect: 'Enemies struck with this weapon take an additional 1d4 cold damage.',
    }
    ,{
        id: 'e04',
        name: 'Boots of Jumping',
        price: '150g',
        type: 'Armor - Boots',
        img: './armor.png',
        summary: 'Why do you even need a description of this?',
        description: 'Do you remember Moon Shoes? These boots look a lot like Moon Shoes. They let the user jump real high and good and high.',
        effect: 'Allows the user to jump their full movement speed. Has 1 charge per day.',
    },{
        id: 'e05',
        name: 'Shield of the Mage Slayer',
        price: '400g',
        type: 'Armor - Shield',
        img: './armor.png',
        summary: 'You\'re REALLY going to want this for that mage tower quest',
        description: 'This beautiful refelctive shield is not only stylish and functional, but it also deflects incoming magical attacks.',
        effect: 'The user has advantage on dexterity saving throws against ranged spell attacks.',
    },{
        id: 'e06',
        name: 'Executioners Axe',
        price: '400g',
        type: '2 Handed Axe',
        img: './weap.png',
        summary: 'It\'s a big axe, for executing',
        description: 'It\'s like twice the size of a normal axe and is caked in dried blood. Someone did not take good care of this. Weak enemies crumble under it\'s power.',
        effect: 'An enemy struck with this weapon with less than 20hp is instantly decapitated.',
    },{
        id: 'e07',
        name: 'The FancyBoy Shave Kit',
        price: '1000g',
        type: 'Trinket',
        img: './trinket.png',
        summary: 'FancyBoy brings you its finest materials to get that "just right" shave. You\'ll be looking good!',
        description: 'A beautiful mahogany box contains finely crafted tools for the nicest of shaves. Brushes, creams, ointments and an elaborate razer can be used every morning look so good, it hurts.',
        effect: 'If used, all melee attacks made against the user will result in 1d4 psychic damage to the attacker. The effect wears off once the day completes. The kit is good for many, many shaves.',
    }
];