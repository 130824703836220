import React from 'react';
import './App.css';
import ShopPage from './Components/ShopPage/ShopPage';

class ItemStore extends React.Component {
  render() {
    return (
      <div className="App">
        <ShopPage />
      </div>
    );
  }
      
}

export default ItemStore;
